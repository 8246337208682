<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.value)">
      <span>{{ texts[params.value - 1] }}</span>
    </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data: () => ({
    texts: [
      'Open',
      'In Proccess',
      'In Proccess Principle',
      'In Proccess Customer',
      'Closed',
    ]
  }),
  computed: {
    chipColor () {
      return (value) => {
        if (value == '1') return 'success'
        else if (value == '2') return 'primary'
        else if (value == '3') return 'dark'
        else if (value == '4') return 'warning'
        else if (value == '5') return 'danger'
        else return 'primary'
      }
    }
  }
}
</script>

<style lang="scss" scpoped>
.vs-chip-success {
  background: rgba(var(--vs-success),.15);
  color: rgba(var(--vs-success),1) !important;
  font-weight: 500;
}
.vs-chip-primary {
  background: rgba(var(--vs-primary),.15);
  color: rgba(var(--vs-primary),1) !important;
  font-weight: 500;
}
.vs-chip-warning {
  background: rgba(var(--vs-warning),.15);
  color: rgba(var(--vs-warning),1) !important;
  font-weight: 500;
}
.vs-chip-danger {
  background: rgba(var(--vs-danger),.15);
  color: rgba(var(--vs-danger),1) !important;
  font-weight: 500;
}
</style>
